import { Component, Inject, } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Artifact } from 'src/app/core/artifacts/classes/artifact';

export interface RemoveDialogData {
  artifact: Artifact;
}
@Component({
  selector: 'app-remove-dialog',
  templateUrl: './remove-dialog.component.html',
  styleUrls: ['./remove-dialog.component.scss']
})
export class RemoveDialogComponent {

  constructor(
    public dialogRef: MatDialogRef<RemoveDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: RemoveDialogData
  ) { }

  onNoClick(): void {
    this.dialogRef.close(undefined);
  }
}
