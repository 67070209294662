import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AppHubComponent } from './modules/apphub/components/apphub/apphub.component';
import { ArtifactsMainComponent } from './modules/artifacts/components/artifacts-main/artifacts-main.component';
import { ArtifactsComponent } from './modules/artifacts/components/artifacts/artifacts.component';


const routes: Routes = [
  { path: 'apphub', component: AppHubComponent },
  { path: '', component: ArtifactsMainComponent },
  { path: '**', component: ArtifactsMainComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
