import {Component, Input, OnInit, SimpleChanges} from '@angular/core';
import {FormControl} from '@angular/forms';
import {DomSanitizer} from '@angular/platform-browser';
import {Observable} from 'rxjs';
import {map, startWith} from 'rxjs/operators';
import {BuildTags, KeycloakRole, TabName} from 'src/app/common/constants';
import {Artifact} from 'src/app/core/artifacts/classes/artifact';
import {TabService} from 'src/app/core/artifacts/services/tab.service';
import {ConfigService} from 'src/app/core/config/config.service';
import {ArtifactService} from '../../../../core/artifacts/artifact.service';
import {Config} from '../../../../core/config/classes/config';

@Component({
  selector: 'app-artifacts',
  templateUrl: './artifacts.component.html',
  styleUrls: ['./artifacts.component.scss'],
})
export class ArtifactsComponent implements OnInit {
  config?: Config;
  artifacts: Artifact[] = [];
  selectedArtifact?: Artifact;
  @Input() currentTab?: string;
  @Input() userRoles?: string[];
  @Input() filterQuery?: string;
  selectedApp?: string;
  selectedBuildType?: string;
  selectedBuildTag?: string;
  selectedBuildStore?: string;
  buildNumber?: string;
  filteredOptions?: Observable<string[] | undefined>;
  filterControl = new FormControl();
  appClassId?: string;

  constructor(
    private artifactService: ArtifactService,
    private configService: ConfigService,
    private sanitizer: DomSanitizer,
    private tabService: TabService
  ) {
  }

  ngOnInit() {
    this.getConfig();
    this.restoreFilters();

    this.filteredOptions = this.filterControl.valueChanges.pipe(
      startWith(''),
      map((value) => this.filterAuto(value))
    );
  }

  ngOnChanges(changes: SimpleChanges): void {
    const userRolesChange = changes.userRoles;
    if (
      userRolesChange != undefined &&
      userRolesChange.currentValue != userRolesChange.previousValue
    ) {
      this.getConfig();
    }
    const change = changes.currentTab;
    if (change === undefined ||
      (change.previousValue !== undefined && change.previousValue !== change.currentValue)) {
      this.subscribeToArtifacts();
    }
  }

  private subscribeToArtifacts() {
    this.getArtifacts().subscribe((artifacts) => {
      this.artifacts = [];
      this.artifacts = artifacts;
    });
  }

  onSelect(artifact: Artifact): void {
    this.selectedArtifact = artifact;
  }

  onScroll(): void {
    this.getArtifacts(this.artifacts[this.artifacts.length - 1].id).subscribe(
      (artifacts) => this.artifacts.push(...artifacts)
    );
  }

  filterAuto(value: string): string[] | undefined {
    const filterValues = value
      .toLowerCase()
      .split(' ')
      .filter((a) => isNaN(Number(a)));

    let values: string[] = [];
    filterValues.forEach((value) => {
      const newValue: string[] | undefined = this.config?.apps
        .filter((option) => option.toLowerCase().includes(value))
        .concat(
          this.config?.appClassIds.filter((option) =>
            option.toLowerCase().includes(value)
          )
        );
      values = values.concat(newValue!);
    });
    return [...new Set(values)];
  }

  /**
   * Get configuration values from backend
   */
  getConfig(): void {
    this.configService.getConfig().subscribe((config) => {
      this.config = config;
    });
  }

  /**
   * Restore filters from previous tab
   */
  restoreFilters(): void {
    this.selectedApp = this.tabService.getSelectedApp();
    this.selectedBuildType = this.tabService.getSelectedBuildType();
    this.selectedBuildStore = this.tabService.getSelectedBuildStore();
    this.selectedBuildTag = this.tabService.getSelectedBuildTag();
  }

  /**
   * Filter builds by selected app name
   * @param event Selected app name
   */
  public queryByApp(event: string): void {
    this.tabService.setSelectedApp(event);
    this.selectedApp = this.tabService.getSelectedApp();
    this.getArtifacts().subscribe((artifacts) => (this.artifacts = artifacts));
  }

  /**
   * Filter builds by selected build store
   * @param event Selected build store
   */
  public queryByBuildStore(event: string): void {
    this.tabService.setSelectedBuildStore(event);
    this.selectedBuildStore = this.tabService.getSelectedBuildStore();
    this.getArtifacts().subscribe((artifacts) => (this.artifacts = artifacts));
  }

  /**
   * Filter builds by selected build tag
   * @param event Selected build tag
   */
  public queryByBuildTag(event: string): void {
    this.tabService.setSelectedBuildTag(event);
    this.selectedBuildTag = this.tabService.getSelectedBuildTag();
    this.getArtifacts().subscribe((artifacts) => (this.artifacts = artifacts));
  }

  /**
   * Filter builds by selected build type
   * @param event Selected build type
   */
  public queryByBuildType(event: string): void {
    this.tabService.setSelectedBuildType(event);
    this.selectedBuildType = this.tabService.getSelectedBuildType();
    this.getArtifacts().subscribe((artifacts) => (this.artifacts = artifacts));
  }

  /**
   * Filter builds by build number and/or appName and/or appClassId
   */
  public filterBuilds() {
    this.appClassId = undefined;
    this.selectedApp = undefined;
    this.buildNumber = undefined;

    this.filterQuery?.split(' ').forEach((searchString) => {
      // Filter by app name or appClassId or build number
      if (this.config?.apps.includes(searchString!)) {
        this.selectedApp = searchString;
      } else if (this.config?.appClassIds.includes(searchString!)) {
        this.appClassId = searchString;
      } else if (searchString !== '') {
        this.buildNumber = searchString;
      }
    });

    if (this.selectedApp == undefined) {
      this.restoreFilters();
    }
    this.getArtifacts().subscribe((artifacts) => (this.artifacts = artifacts));
  }

  /**
   * Replaces typed value in the input field with the chosen value in a selector. Selector contains valid values from the config.
   * @param selectorValue user's selected value in the selector
   * @return corrected content of the input field
   */
  replaceInput(selectorValue: string) {
    let correctedContent = '';
    const value = selectorValue.toLowerCase();
    const inputValues = this.filterQuery?.split(' ')!;
    inputValues.forEach((inputValue) => {
      if (value.includes(inputValue.toLowerCase())) {
        correctedContent += selectorValue;
      } else {
        correctedContent += inputValue;
      }
      correctedContent += ' ';
    });
    let withoutDuplicates = new Set(correctedContent.split(' '));
    return [...withoutDuplicates].join(' ');
  }

  /**
   * Fetches artifact documents from backend
   * @param documentId ID of the last fetched document to fetch the next page
   */
  getArtifacts(documentId?: string): Observable<Artifact[]> {
    if (this.currentTab === TabName.USER_TEST_TAB) {
      this.selectedBuildTag = BuildTags.USER_TEST;
    } else if (this.currentTab === TabName.ARCHIVE_TAB) {
      this.selectedBuildTag = BuildTags.ARCHIVE;
    }

    return this.artifactService.getArtifactData(
      this.selectedApp,
      this.selectedBuildType,
      this.selectedBuildStore,
      this.selectedBuildTag,
      this.buildNumber,
      documentId,
      this.appClassId
    );
  }

  checkArchiveUser(): boolean {
    return this.userRoles!.includes(KeycloakRole.ALLOW_ARCHIVING);
  }
}
