<div class="d-flex row">
  <div class="float-left form-field-container col-md col-sm col-xs-12">
    <mat-form-field>
      <mat-select
        placeholder="{{app}}"
        (selectionChange)="selectApp($event)"
      >
        <mat-option [value]="noneValue">None</mat-option>
        <mat-option *ngFor="let app of config?.apps" [value]="app">
          {{ app }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>
  <div class="float-left form-field-container col-md col-sm col-xs-12">
    <mat-form-field>
      <mat-select
        placeholder="{{buildType}}"
        (selectionChange)="selectBuildType($event)"
      >
        <mat-option [value]="noneValue">None</mat-option>
        <mat-option *ngFor="let type of config?.buildTypes" [value]="type">
          {{ type }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>
  <div class="float-left form-field-container col-md col-sm col-xs-12">
    <mat-form-field>
      <mat-select
        placeholder="{{buildStore}}"
        (selectionChange)="selectBuildStore($event)"
      >
        <mat-option [value]="noneValue">None</mat-option>
        <mat-option *ngFor="let store of config?.buildStores" [value]="store">
          {{ store }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>
  <div class="float-left form-field-container col-md col-sm col-xs-12">
    <mat-form-field>
      <mat-select
        placeholder="{{buildTag}}"
        (selectionChange)="selectBuildTag($event)"
      >
        <mat-option [value]="noneValue">None</mat-option>
        <mat-option *ngFor="let tag of config?.buildTags" [value]="tag">
          {{ tag }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>
</div>
