import { EventEmitter } from '@angular/core';
import { Component, Input, OnInit, Output } from '@angular/core';
import { Config } from '../../../../core/config/classes/config';
import { TabService } from 'src/app/core/artifacts/services/tab.service';

@Component({
  selector: 'app-artifact-filters',
  templateUrl: './artifact-filters.component.html',
  styleUrls: ['./artifact-filters.component.scss'],
})
export class ArtifactFiltersComponent implements OnInit {
  @Input() config?: Config;
  @Output() selectedApp = new EventEmitter<string>();
  @Output() selectedBuildType = new EventEmitter<string>();
  @Output() selectedBuildStore = new EventEmitter<string>();
  @Output() selectedBuildTag = new EventEmitter<string>();

  readonly defaultAppText: string = "Select app...";
  readonly defaultTypeText: string = "Select build type...";
  readonly defaultStoreText: string = "Select build store...";
  readonly defaultTagText: string = "Select build tag...";

  readonly noneValue: string = 'none';

  app: string = this.defaultAppText;
  buildType: string = this.defaultTypeText;
  buildStore: string = this.defaultStoreText
  buildTag: string = this.defaultTagText;

  constructor(private tabService: TabService) {}

  ngOnInit(): void {
    this.updatePlaceholders();
  }

  private updatePlaceholders(){
    if (this.tabService.getSelectedApp() != undefined) {
      this.app = this.tabService.getSelectedApp()!;
    }
    if (this.tabService.getSelectedBuildType() != undefined) {
      this.buildType = this.tabService.getSelectedBuildType()!;
    }
    if (this.tabService.getSelectedBuildTag() != undefined) {
      this.buildTag = this.tabService.getSelectedBuildTag()!;
    }
    if (this.tabService.getSelectedBuildStore() != undefined) {
      this.buildStore = this.tabService.getSelectedBuildStore()!;
    }
  }

  public selectApp(event: any): void {
    if (event.value == this.noneValue) {
      this.app  = this.defaultAppText;
      this.selectedApp.emit(undefined);
    } else {
      this.app = event.value;
      this.selectedApp.emit(event.value);
    }
  }

  public selectBuildType(event: any): void {
    if (event.value == this.noneValue) {
      this.buildType = this.defaultTypeText;
      this.selectedBuildType.emit(undefined);
    } else {
      this.buildType = event.value;
      this.selectedBuildType.emit(event.value);
    }
  }

  public selectBuildStore(event: any): void {
    if (event.value == this.noneValue) {
      this.buildStore = this.defaultStoreText;
      this.selectedBuildStore.emit(undefined);
    } else {
      this.buildStore = event.value;
      this.selectedBuildStore.emit(event.value);      
    }
  }

  public selectBuildTag(event: any): void {
    if (event.value == this.noneValue) {
      this.buildTag = this.defaultTagText;
      this.selectedBuildTag.emit(undefined);
    } else {
      this.buildTag = event.value;
      this.selectedBuildTag.emit(event.value);  
    }
  }
}
