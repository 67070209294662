<div class="button-row">
  <div>
    <div class="btn-group mr-4 button-container">
      <button mat-raised-button color="primary" [disabled]="isInProgress" (click)="downloadAndroid()">Install Android build</button>
      <mat-progress-spinner mode="indeterminate" diameter="30" *ngIf="isInProgress"></mat-progress-spinner>
    </div>
    <div class="btn group mr-4 button-container" style="min-width: 100%;">

    </div>
    <div class="btn-group mr-4 button-container">
      <a mat-raised-button color="primary" [href]="downloadiOS()">Install iOS build</a>
    </div>
  </div>
</div>
