import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { catchError, map, tap } from 'rxjs/operators';
import { Errors } from '../utils/errors'

@Injectable({
  providedIn: 'root'
})
export class AppHubService {

  API_URL = environment.api.url;


  constructor(private httpClient: HttpClient) { }

  public getAppHubSignedUrl() {

    return this.httpClient
          .get<URL>(this.API_URL + `/apphub/android`)
          .pipe(catchError(Errors.handleError<URL>('downloadAppHub')));
  }

}
