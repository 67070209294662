import { ConfigResponse } from './config-response';

export class Config {
  apps: string[];
  appClassIds: string[];
  buildTypes: string[];
  buildStores: string[];
  buildTags: string[];

  constructor(config: ConfigResponse) {
    this.buildTypes = config.buildTypes;
    this.buildStores = config.buildStores;
    this.buildTags = config.buildTags;

    this.apps = [];
    this.appClassIds = [];
    Object.keys(config.apps).forEach((key) => {
      this.apps.push(...config.apps[key]);
      this.appClassIds.push(key);
    });

    this.apps.sort((first, second) => (first > second ? 1 : -1))
    // Remove duplicates (backend bug where we had two entries for TalkingTomGoldRun)
    // Can be removed once we fix backend duplicates
    this.apps = this.apps.filter((n, i) => this.apps.indexOf(n) === i);
    // Remove whitespace because it breaks everything
    this.apps = this.apps.map(app => app.replace(/\s/g, ""))
  }
}
