<div *ngIf="artifact">
  <h3>Build information:</h3>
  <div>
    <ul>
      <li>artifact id: {{ artifact.id }}</li>
      <li>platform: {{ artifact.appPlatform }}</li>
      <li>app version: {{ artifact.appVersion }}</li>
      <li>store: {{ artifact.buildStore }}</li>
      <li>tags: {{ artifact.buildTag }}</li>
      <li>build type: {{ artifact.buildType }}</li>
      <li>branch: {{ artifact.branch }}</li>
      <li>created: {{ artifact.created }}</li>
      <li>file hash: {{ artifact.fileHash }}</li>
      <li>package name: {{ artifact.packageName }}</li>
      <li *ngIf="artifact.archivedDate !== undefined">archived: {{ artifact.archivedDate }} </li>
      <li *ngIf="artifact.size !== undefined">size: {{ artifact.size }}</li>
      <li *ngIf="artifact.lucCommitSHA !== undefined">
        luc commit SHA: <b>{{ artifact.lucCommitSHA }}</b>
      </li>
      <li *ngIf="artifact.nativeCommitSHA !== undefined">
        native commit SHA:  <b>{{ artifact.nativeCommitSHA }}</b>
      </li>
      <li style="font-weight: bold" *ngIf="artifact.felisVersion !== undefined">
        felis version: {{ artifact.felisVersion }}
      </li>
      <li *ngIf="artifact.commitSHA !== undefined">
        Commit SHA: <a href="https://bitbucket.org/outfit7/{{ artifact.projectName }}/commits/{{ artifact.commitSHA }}">{{ artifact.commitSHA }}</a>
      </li>
      <li>
        <b>Scan to install</b><br>
        <img [src]="getQrCodeDeeplinkDataURL(artifact)" width="150" height="150"/>
      </li>
    </ul>
  </div>
  <div class="btn-toolbar">
    <div class="btn-group mr-2">
      <a
        mat-raised-button
        color="primary"
        [href]="generatePlistUrl()"
        [hidden]="artifact.appPlatform.toLowerCase() !== 'ios'"
        *ngIf="checkQAMacTesterUser() == true"
      >Install iOS build</a
      >
    </div>
    <div class="btn-group mr-2">
      <button
        mat-raised-button
        color="primary"
        (click)="downloadArtifactWithId()"
      >
        Download all related build files
      </button>
    </div>
    <div class="btn-group mr-2">
      <button
        mat-raised-button
        color="primary"
        (click)="downloadArtifactWithId(artifact.buildType)"
        *ngIf="checkReleaseBuildDownload(artifact)"
      >
        Download Release build
      </button>
    </div>
    <div class="btn-group mr-2">
      <button
        mat-raised-button
        color="primary"
        (click)="uploadBuild()"
        *ngIf="checkArchiveUser() == true"
      >
        <div>Upload build</div>
      </button>
    </div>
    <div class="btn-group mr-2">
      <a
        mat-raised-button
        color="warn"
        (click)="archive()"
        *ngIf="currentTab != 'Archive'  && isArchived == false && checkArchiveUser()"
      >
        Archive
      </a>
    </div>
    <div class="btn-group mr-2">
      <a
        mat-raised-button disabled
        matTooltip="This build has already been archived"
        color="disabled"
        *ngIf="isArchived == true && currentTab != 'Archive' && checkArchiveUser()"
      >
        Archive
      </a>
    </div>
    <div class="btn-group mr-2">
      <button
        mat-raised-button
        color="warn"
        (click)="removeBuild()"
        *ngIf=" currentTab == 'Archive' && !isDeleted && !artifact.deleted && checkArchiveUser()"
      >
        <div>Remove from Archive</div>
      </button>
    </div>
    <div class="btn-group mr-2">
      <button
        mat-raised-button
        color="basic"
        (click)="restoreBuild()"
        *ngIf=" currentTab == 'Archive' && !isRestored && artifact.deleted && checkArchiveUser()"
      >
        <div>Restore to Archive</div>
      </button>
    </div>
    <div>
      <mat-progress-spinner mode="indeterminate" *ngIf="isInProgress" diameter="30"></mat-progress-spinner>
    </div>
    <div class="error" *ngIf="this.artifactService.error != undefined">
      <span>{{ this.artifactService.error }}</span>
    </div>
    <div class="success" *ngIf="this.feedback != undefined">
      <span>{{ this.feedback }}</span>
    </div>
    <div class="success" *ngIf="this.uploadFeedback != undefined">
      <span>Link to the CircleCI upload job: <a href={{this.uploadFeedback.circleCILink}} target="_blank">{{ this.uploadFeedback.circleCILink }}</a></span>
      <span>Link to the store: <a href={{this.uploadFeedback.storeLink}} target="_blank">{{ this.uploadFeedback.storeLink }}</a></span>
    </div>
  </div>
</div>
