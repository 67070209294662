import { Component, OnInit, Input } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { AppHubService } from '../../../../core/apphub/apphub.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-apphub',
  templateUrl: './apphub.component.html',
  styleUrls: ['./apphub.component.scss']
})
export class AppHubComponent implements OnInit {

  @Input() isInProgress: boolean = false;

  constructor(
    private appHubService: AppHubService,
    private sanitizer: DomSanitizer
    ) { }

  ngOnInit(): void {
  }

  public downloadAndroid() {
    this.isInProgress = true;
    return this.appHubService.getAppHubSignedUrl().subscribe(
      (data) => {
        const element = document.createElement('a');
        element.href = data.toString();
        element.click();
        this.isInProgress = false;
        });
  }

  public downloadiOS() {
    let url = this.generatePlistUrl();
    console.log(url);
    return url;
  }

  generatePlistUrl() {
    let baseUrl = 'itms-services://?action=download-manifest&url=';
    return this.sanitizer.bypassSecurityTrustUrl(
      baseUrl +
        environment.api.url +
        '/apphub/iOS'
    );
  }
}
