import {Component, Inject} from '@angular/core';
import {MatCheckboxChange} from '@angular/material/checkbox';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {Artifact} from 'src/app/core/artifacts/classes/artifact';
import {TestFlightGroup} from 'src/app/core/artifacts/classes/test-flight-group';

export interface DialogData {
  artifact: Artifact,
  testFlightGroups: TestFlightGroup[],
  track: string,
  publish: string
  overwriteTexts: boolean;
}

@Component({
  selector: 'app-upload-dialog',
  templateUrl: './upload-dialog.component.html',
  styleUrls: ['./upload-dialog.component.scss']
})

export class UploadDialogComponent {

  constructor(
    public dialogRef: MatDialogRef<UploadDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) {}

  tracks = [
    "alpha",
    "beta",
    "internal",
    "production"
  ]

  onNoClick(): void {
    this.dialogRef.close();
  }

  onChange(event: MatCheckboxChange, name: string) {
    this.data.testFlightGroups
      .map(group => {
        if (group.name == name) {
          group.chosen = event.checked;
          return group;
        }
        return group;
      })
  }

}
