export class TabName {
  public static ALL_TAB = 'All';
  public static USER_TEST_TAB = 'User Test';
  public static ARCHIVE_TAB = 'Archive';
}

export class KeycloakRole {
  public static ALLOW_ARCHIVING = 'ROLE_APPROCKET_ALLOW_ARCHIVING';
  public static ALLOW_IOS_DOWNLOAD = 'ROLE_ALLOW_IOS_INSTALL_ON_WEB'
}

export class Platforms {
  public static IOS_PLATFORM = 'iOS';
  public static ANDROID_PLATFORM = 'Android';
}

export class BuildTypes {
  public static RELEASE = 'Release'
  public static PROD = 'Prod'
  public static DEVEL = 'Devel'
}

export class BuildTags {
  public static USER_TEST = 'USER_TEST'
  public static ARCHIVE = 'ARCHIVE'
}
