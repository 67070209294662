import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Artifact } from 'src/app/core/artifacts/classes/artifact';

export interface RestoreDialogData {
  artifact: Artifact;
}
@Component({
  selector: 'app-restore-dialog',
  templateUrl: './restore-dialog.component.html',
  styleUrls: ['./restore-dialog.component.scss']
})
export class RestoreDialogComponent {

  constructor(
  public dialogRef: MatDialogRef<RestoreDialogComponent>,
      @Inject(MAT_DIALOG_DATA) public data: RestoreDialogData
  ) { }

  onNoClick(): void {
    this.dialogRef.close(undefined);
  }
}
