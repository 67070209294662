import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable} from 'rxjs';
import {catchError, map} from 'rxjs/operators';
import {environment} from 'src/environments/environment';
import {Artifact} from './classes/artifact';
import {ArtifactResponse} from './classes/artifact-response';
import {Errors} from '../utils/errors';

@Injectable({
  providedIn: 'root',
})
export class ArtifactService {

  API_URL = environment.api.url;

  public error: any;

  constructor(private httpClient: HttpClient) {}

  public getArtifactData(
    app?: string,
    type?: string,
    store?: string,
    tag?: string,
    buildNumber?: string,
    documentId?: string,
    appClassId?: string,
    deleted: boolean = true
  ): Observable<Artifact[]> {
    let queryParams = this.constructQueryParameters(
      app,
      type,
      store,
      tag,
      buildNumber,
      documentId,
      appClassId,
      deleted
    );

    return this.httpClient
      .get<ArtifactResponse[]>(this.API_URL + `/list?limit=30&${queryParams}`)
      .pipe(
        map((response) => {
          return this.mapArtifactResponse(response);
        })
      )
      .pipe(catchError(Errors.handleError<Artifact[]>('getArtifactData', [])));
  }

  public getPlistForArtifact(documentId?: string): Observable<Blob> {
    return this.httpClient
      .get<Blob>(this.API_URL + `/dashboard/plist/${documentId}`, {
        responseType: 'blob' as 'json',
      })
      .pipe(catchError(Errors.handleError<Blob>('getPlistForArtifact')));
  }

  public downloadArtifact(documentId?: string, buildType?: string): Observable<string> {
    let headers = new HttpHeaders().set('Content-Type', 'application/json');
    let queryParams = buildType === undefined ? "" : `?buildType=${buildType}`
    return this.httpClient
      .post<string>(
        this.API_URL + `/download/web/${documentId}${queryParams}`,
        { headers, responseType: 'blob' as 'json' }
      )
      .pipe(catchError(Errors.handleError<string>('downloadArtifact')));
  }

  public archiveArtifact(documentId: string, buildStores: string[]): Observable<any> {
    let headers = new HttpHeaders().set('Content-Type', 'application/json');
    let body = {
      "artifactId": documentId,
      "buildStores": buildStores
    }

    this.error = undefined;
    return this.httpClient
      .post(this.API_URL + '/dashboard/archive', body, { headers })
      .pipe(
        catchError(error => {
          let operation = 'archiveArtifact';
          this.error = `Operation ${operation} for artifact ${documentId} failed: ${error.message}` + ("error" in error ? ` with error: ` + JSON.stringify(error.error) : ``);
          return Errors.handleError<any>(operation)(error);
        })
      );
  }

  public getTestFlightGroups(appClassId: string): Observable<string[]> {
    return this.httpClient
      .get<string[]>(this.API_URL + '/dashboard/testflightgroup/' + appClassId)
  }

  public deleteArtifact(documentId: string): Observable<any> {
    this.error = undefined;
    return this.httpClient
      .put(this.API_URL + '/dashboard/remove-from-ui/' + documentId + '/' + true,  {})
      .pipe(
        catchError(error => {
          let operation = 'deleteArtifact';
          this.error = `Operation ${operation} for artifact ${documentId} failed: ${error.message}` + ("error" in error ? ` with error: ` + JSON.stringify(error.error) : ``);
          return Errors.handleError<any>(operation)(error);
        })
      );
  }

  public restoreArtifact(documentId: string): Observable<any> {
    this.error = undefined;
    return this.httpClient
      .put(this.API_URL + '/dashboard/remove-from-ui/' + documentId + '/' + false,  {})
      .pipe(
        catchError(error => {
          let operation = 'restoreArtifact';
          this.error = `Operation ${operation} for artifact ${documentId} failed: ${error.message}` + ("error" in error ? ` with error: ` + JSON.stringify(error.error) : ``);
          return Errors.handleError<any>(operation)(error);
        })
      );
  }

  public uploadToCircleCIPipeline(platform: string, appClassId: string, buildNumber: number, buildType: string, submitToTestFlight: boolean, track: string, flavour: string, groups: string, overwriteTexts: boolean) : Observable<any>{
    const branch = environment.upload_to_store.branch;
    return this.httpClient
      .post(
        this.API_URL + '/dashboard/upload',
        {
          "branch": branch,
          "parameters": {
            "platform": platform,
            "app-class-id": appClassId,
            "build-number": buildNumber,
            "build-type": buildType,
            "build-flavor": flavour,
            "overwrite-texts": overwriteTexts,
            "submit-to-testflight": submitToTestFlight,
            "testflight-groups": groups,
            "track": track
          }
        },
        { }
      )
      .pipe(
        catchError(error => {
          let operation = 'uploadToTheStore';
          this.error = `${operation} failed: ${error.message}` + ("error" in error ? ` with error: ` + JSON.stringify(error.error) : ``);
          return Errors.handleError<any>(operation)(error);
      }));
  }

  private mapArtifactResponse(
    artifactResponse: ArtifactResponse[]
  ): Artifact[] {
    let artifacts: Artifact[] = [];
    artifactResponse.forEach((item) => {
      let artifact = new Artifact(item);
      artifacts.push(artifact);
    });
    return artifacts;
  }

  private constructQueryParameters(
    app?: string,
    type?: string,
    store?: string,
    tag?: string,
    buildNumber?: string,
    documentId?: string,
    appClassId?: string,
    deleted?: boolean
  ): string {
    let params: string[] = [];

    if (type !== undefined) {
      params.push(`buildType=${type}`);
    }

    if (app !== undefined) {
      let appNameWithSpaces = ArtifactService.addSpacesToAppName(app);
      params.push(`appName=${appNameWithSpaces.trim()}`);
    }

    if (store !== undefined) {
      params.push(`buildStore=${store}`);
    }

    if (tag !== undefined) {
      params.push(`buildTag=${tag}`);
    }

    if (buildNumber !== undefined && buildNumber !== null) {
      params.push(`buildNumber=${buildNumber}`);
    }

    if (documentId !== undefined) {
      params.push(`documentId=${documentId}`);
    }

    if (appClassId !== undefined) {
      params.push(`appClassId=${appClassId}`);
    }

    if (deleted !== undefined) {
      params.push(`deleted=${deleted}`);
    }

    return params.join('&');
  }

  private static addSpacesToAppName(app: string): string {
    // Because of inconsistencies, Ginger doesn't have a space between Ginger and 2.
    if (app === "Ginger2" || app === "Hank2") {
      return app;
    }
    if (app === "TalkingBenAI" || app === "TalkingBen.AI") {
      return ArtifactService.workaroundForBenAI(app);
    }
    return app.split(/(?=[A-Z\d])/).join(' ');
  }

  // Dirty hack because otherwise search for BenAI doesn't work and we're going to kill dist soo anyway, so I don't
  // want to do it properly
  private static workaroundForBenAI(app: string): string {
    if (app === "TalkingBen.AI") {
      return "Talking Ben.AI";
    }
    return "Talking BenAI";
  }
}
