import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { of } from 'rxjs';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { ConfigResponse } from 'src/app/core/config/classes/config-response';
import { environment } from 'src/environments/environment';
import { Config } from './classes/config';

@Injectable({
  providedIn: 'root',
})
export class ConfigService {
  API_URL = environment.api.url;

  constructor(private httpClient: HttpClient) {}

  /**
   * Fetch config from server (available app names, buildTags, etc.)
   */
  public getConfig(): Observable<Config> {
    return this.httpClient
      .get<ConfigResponse>(this.API_URL + `/list/config`)
      .pipe(
        map((response) => {
          let config = new Config(response);
          return config;
        })
      )
      .pipe(catchError(this.handleError<Config>('getConfig')));
  }

  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      console.error(error);
      this.log(`${operation} failed: ${error.message}`);
      return of(result as T);
    };
  }

  private log(message: string): void {
    console.log(`ArtifactService: ${message}`);
  }
}
