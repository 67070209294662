import { Pipe, PipeTransform } from '@angular/core';
import { Artifact } from 'src/app/core/artifacts/classes/artifact';

@Pipe({
  name: 'artifactsFilter',
})
export class ArtifactsFilterPipe implements PipeTransform {
  transform(artifacts: Artifact[], filterText: string): Artifact[] {
    if (!artifacts) {
      return [];
    }
    if (!filterText) {
      return artifacts;
    }

    return artifacts.filter((artifact) => {
      return this.filterByText(artifact, filterText);
    });
  }

  private filterByText(artifact: Artifact, filterText: string): boolean {
    let keys = filterText.toLowerCase().split(' ');
    for (let key of keys) {
      if (!this.artifactContainsKeyword(artifact, key)) {
        return false;
      }
    }
    return true;
  }

  private artifactContainsKeyword(
    artifact: Artifact,
    keyword: string
  ): boolean {
    return (
      artifact.appName.toLocaleLowerCase().includes(keyword) ||
      artifact.buildNumber.includes(keyword) ||
      artifact.appVersion.includes(keyword) ||
      artifact.buildTag.includes(keyword) ||
      artifact.appPlatform.toLocaleLowerCase().includes(keyword) ||
      artifact.appCode.toLocaleLowerCase().includes(keyword)
    );
  }
}
