<div class="input-group">
  <form (keydown.enter)="filterBuilds()" style="display: inline-flex; width: 100%">
    <input
      type="text"
      [(ngModel)]="filterQuery"
      class="form-control search"
      placeholder="Search by build number or app name or appClassId"
      [formControl]="filterControl"
      [matAutocomplete]="auto"
    />
      <mat-autocomplete #auto="matAutocomplete">
        <mat-option *ngFor="let appName of this.filteredOptions | async" [value]="this.replaceInput(appName)">
          {{appName}}
        </mat-option>
      </mat-autocomplete>
    <button
      mat-raised-button
      color="primary"
      (click)="filterBuilds()"
      class="search-button"
    >
      Filter
    </button>
  </form>
</div>
<div>
  <app-artifact-filters
    (selectedApp)="queryByApp($event)"
    (selectedBuildStore)="queryByBuildStore($event)"
    (selectedBuildTag)="queryByBuildTag($event)"
    (selectedBuildType)="queryByBuildType($event)"
    [config]="config"
  ></app-artifact-filters>
</div>
<div infinite-scroll [infiniteScrollDistance]="2" (scrolled)="onScroll()" >
  <mat-accordion>
    <ng-container *ngFor="let artifact of artifacts">
      <div *ngIf="checkArchiveUser(); then manager else user" ></div>
      <ng-template #manager>
        <app-panel [artifact]="artifact" [currentTab]="currentTab" [userRoles]="userRoles" [selectedArtifact]="selectedArtifact"></app-panel>
      </ng-template>
      <ng-template #user>
        <app-panel *ngIf="!artifact.deleted" [artifact]="artifact" [currentTab]="currentTab" [userRoles]="userRoles" [selectedArtifact]="selectedArtifact"></app-panel>
      </ng-template>
    </ng-container>
  </mat-accordion>
</div>
