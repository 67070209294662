<h1 mat-dialog-title>Upload build to the store</h1>
<div mat-dialog-content *ngIf="data.artifact.buildStore == 'Google'">
    <p>Select a Gplay publish track:</p>
    <mat-select
        placeholder="Track"
        [(ngModel)]="data.track"
    >
    <mat-option *ngFor="let track of tracks" [value]="track">
        {{track}}
      </mat-option>
    </mat-select>
</div>
<div mat-dialog-content *ngIf="data.artifact.appPlatform == 'iOS'">
    <p>Publish to TestFlight?</p>
    <mat-radio-group aria-label="Select an option" [(ngModel)]="data.publish">
        <mat-radio-button value="true">Yes</mat-radio-button>
        <mat-radio-button value="false">No</mat-radio-button>
    </mat-radio-group>
    <p>Overwrite texts with those from the spreadsheet?</p>
    <mat-radio-group aria-label="Select an option" [(ngModel)]="data.overwriteTexts">
      <mat-radio-button value="true">Yes</mat-radio-button>
      <mat-radio-button value="false">No</mat-radio-button>
    </mat-radio-group>
</div>
<div mat-dialog-content *ngIf="data.publish == 'true'">
    <p>Select TestFlight groups:</p>
    <div *ngFor="let group of data.testFlightGroups">
        <mat-checkbox [(ngModel)]="group.chosen" (change)="onChange($event, group.name)">
          {{group.name}}
        </mat-checkbox>
      </div>
</div>
<mat-dialog-actions align="end">
    <button mat-button [mat-dialog-close]="data">Ok</button>
</mat-dialog-actions>
