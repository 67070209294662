export class TabService {

  private selectedApp?: string;
  private selectedBuildType?: string;
  private selectedBuildStore?: string;
  private selectedBuildTag?: string;

  public getSelectedApp(){
    return this.selectedApp;
  }

  public getSelectedBuildType(){
    return this.selectedBuildType;
  }

  public getSelectedBuildStore(){
    return this.selectedBuildStore;
  }

  public getSelectedBuildTag(){
    return this.selectedBuildTag;
  }

  public setSelectedApp(app?: string){
    this.selectedApp = app;
  }

  public setSelectedBuildType(buildType?: string){
    this.selectedBuildType = buildType;
  }

  public setSelectedBuildStore(buildStore?: string){
    this.selectedBuildStore = buildStore;
  }

  public setSelectedBuildTag(buildTag?: string){
    this.selectedBuildTag = buildTag;
  }

}
