import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HttpClientModule } from '@angular/common/http';
import { ArtifactsComponent } from './modules/artifacts/components/artifacts/artifacts.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ArtifactDetailComponent } from './modules/artifacts/components/artifact-detail/artifact-detail.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { initializeKeycloak } from '../utils/keycloak-init';
import { KeycloakService, KeycloakAngularModule } from 'keycloak-angular';
import { ArtifactListComponent } from './modules/artifacts/components/artifact-list/artifact-list.component';
import { MatTabsModule } from '@angular/material/tabs';
import { MatInputModule } from '@angular/material/input';
import { MatIconModule } from '@angular/material/icon';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatButtonModule } from '@angular/material/button';
import { ArtifactsFilterPipe } from './modules/artifacts/pipes/artifacts-filter.pipe';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { MatSelectModule } from '@angular/material/select';
import { ArtifactFiltersComponent } from './modules/artifacts/components/artifact-filters/artifact-filters.component';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import { UploadDialogComponent } from './modules/artifacts/components/upload-dialog/upload-dialog.component';
import { MatDialogModule } from '@angular/material/dialog';
import { MatRadioModule } from '@angular/material/radio';
import { MatToolbarModule } from '@angular/material/toolbar';
import { AppHubComponent } from './modules/apphub/components/apphub/apphub.component';
import { ArtifactsMainComponent } from './modules/artifacts/components/artifacts-main/artifacts-main.component';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import { ArchiveDialogComponent } from './modules/artifacts/components/archive-dialog/archive-dialog.component';
import {MatCheckboxModule} from '@angular/material/checkbox';
import { MatTooltipModule } from '@angular/material/tooltip'
import { RemoveDialogComponent } from './modules/artifacts/components/remove-dialog/remove-dialog.component';
import { PanelComponent } from './modules/artifacts/components/panel/panel.component';
import { RestoreDialogComponent } from './modules/artifacts/components/restore-dialog/restore-dialog.component';

@NgModule({
  declarations: [
    AppComponent,
    ArtifactsComponent,
    ArtifactDetailComponent,
    ArtifactListComponent,
    ArtifactsFilterPipe,
    ArtifactFiltersComponent,
    UploadDialogComponent,
    AppHubComponent,
    ArtifactsMainComponent,
    ArchiveDialogComponent,
    RemoveDialogComponent,
    PanelComponent,
    RestoreDialogComponent
  ],
  imports: [
    BrowserModule,
    FormsModule,
    MatTabsModule,
    MatInputModule,
    MatExpansionModule,
    MatIconModule,
    MatButtonModule,
    AppRoutingModule,
    HttpClientModule,
    BrowserAnimationsModule,
    KeycloakAngularModule,
    InfiniteScrollModule,
    MatSelectModule,
    ReactiveFormsModule,
    MatToolbarModule,
    MatProgressSpinnerModule,
    MatDialogModule,
    MatRadioModule,
    MatAutocompleteModule,
    MatCheckboxModule,
    MatTooltipModule
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: initializeKeycloak,
      multi: true,
      deps: [KeycloakService],
    }
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
