import { Component, Inject } from '@angular/core';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Artifact } from 'src/app/core/artifacts/classes/artifact';
import { BuildStore } from 'src/app/core/artifacts/classes/build-store';

export interface ArchiveDialogData {
  artifact: Artifact,
  buildStores: BuildStore[]
}

@Component({
  selector: 'app-archive-dialog',
  templateUrl: './archive-dialog.component.html',
  styleUrls: ['./archive-dialog.component.scss']
})

export class ArchiveDialogComponent {

  constructor(
    public dialogRef: MatDialogRef<ArchiveDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ArchiveDialogData
  ) {}

  onNoClick(): void {
    this.dialogRef.close(undefined);
  }

  onChange(event: MatCheckboxChange, name: string) {
    this.data.buildStores
      .map(store => {
        if (store.name == name) {
          store.chosen = event.checked;
          return store;
        }
        return store;
      })
  }

}