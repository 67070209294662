import { Platforms } from 'src/app/common/constants';
import { ArtifactResponse } from './artifact-response';

export class Artifact {
  id: string;
  appName: string;
  appPlatform: string;
  appCode: string;
  appClassId: string;
  appVersion: string;
  buildNumber: string;
  buildStore: string;
  buildTag: string[];
  buildType: string;
  created: Date;
  archivedDate?: Date;
  fileHash: string;
  fileName: string;
  icon: string;
  branch: string;
  size: string | undefined;
  lucCommitSHA: string | undefined;
  nativeCommitSHA: string | undefined;
  commitSHA: string | undefined;
  packageName: string | undefined;
  felisVersion: string | undefined;
  deleted: boolean;
  projectName: string;

  constructor(artifact: ArtifactResponse) {
    this.id = artifact.id;
    this.appName = artifact.appName;
    this.appPlatform = artifact.appPlatform;
    this.appCode = artifact.appCode;
    this.appClassId = artifact.appClassId;
    this.appVersion = artifact.appVersion;
    this.buildNumber = artifact.buildNumber;
    this.buildStore = artifact.buildStore;
    this.buildTag = artifact.buildTag;
    this.buildType = artifact.buildType;
    this.created = this.getCreatedDateFromTimestamp(artifact);
    this.archivedDate = this.getArchivedDateFromTimestamp(artifact);
    this.fileHash = artifact.fileHash;
    this.fileName = artifact.fileName;
    this.icon = artifact.icon;
    this.branch = artifact.branch;
    this.size = this.getSizeFromMetadata(artifact);
    this.lucCommitSHA = this.getLucCommitSHA(artifact);
    this.nativeCommitSHA = this.getNativeCommitSHA(artifact);
    this.commitSHA = this.getCommitSHA(artifact);
    this.packageName = this.getPackageName(artifact);
    this.felisVersion = this.getFelisVersion(artifact);
    this.deleted = artifact.deleted;
    this.projectName = artifact.projectName;
  }

  private getCreatedDateFromTimestamp(artifact: ArtifactResponse): Date {
    return new Date(artifact.created);
  }

  private getArchivedDateFromTimestamp(
    artifact: ArtifactResponse
  ): Date | undefined {
    if (artifact.archivedDate == undefined) {
      return undefined;
    }
    return new Date(artifact.archivedDate);
  }

  private getSizeFromMetadata(artifact: ArtifactResponse): string | undefined {
    if (!('size' in artifact.metadata && artifact.metadata['size'] !== '' && artifact.appPlatform !== Platforms.IOS_PLATFORM)) {
      return undefined;
    }

    if (artifact.fileName.endsWith('.aab')) {
      let splittedValues: number[] = artifact.metadata['size']
        .replace('MIN,MAX', '')
        .split(',')
        .map((v) => parseInt(v) / 1_000_000);

      if (splittedValues.filter((v) => v != 0).length == 0) {
        return undefined;
      }

      return '[' + splittedValues.map((v) => v.toFixed(2) + ' MB').join(', ') + ']';
    } else {
      let size = +artifact.metadata['size'] / 1_000_000;

      if (size === 0) {
        return undefined;
      }
      return size.toFixed(2) + ' MB';
    }
  }

  private getLucCommitSHA(artifact: ArtifactResponse): string | undefined {
    if (
      !(
        'luc-commit-sha' in artifact.metadata &&
        artifact.metadata['luc-commit-sha'] !== ''
      )
    ) {
      return undefined;
    }
    return artifact.metadata['luc-commit-sha'];
  }

  private getNativeCommitSHA(artifact: ArtifactResponse): string | undefined {
    if (
      !(
        'native-commit-sha' in artifact.metadata &&
        artifact.metadata['native-commit-sha'] !== ''
      )
    ) {
      return undefined;
    }
    return artifact.metadata['native-commit-sha'];
  }

  private getCommitSHA(artifact: ArtifactResponse): string | undefined {
    if (
      !(
        'commit-sha' in artifact.metadata &&
        artifact.metadata['commit-sha'] !== ''
      )
    ) {
      return undefined;
    }
    return artifact.metadata['commit-sha'];
  }

  private getPackageName(artifact: ArtifactResponse): string | undefined {
    if (
      !(
        'package-name' in artifact.metadata &&
        artifact.metadata['package-name'] !== ''
      )
    ) {
      return undefined;
    }
    return artifact.metadata['package-name'];
  }

  private getFelisVersion(artifact: ArtifactResponse): string | undefined {
    if (
      !(
        'dependencies' in artifact.metadata &&
        artifact.metadata['dependencies'] !== ''
      )
    ) {
      return undefined;
    }

    const felisDependency = 'com.outfit7.felis:core';
    let dependencies = artifact.metadata['dependencies']
      .split('Dependency:')
      .filter((dep) => dep.includes(felisDependency));
    if (dependencies.length == 0) {
      return undefined;
    }

    return dependencies[0].split(':').pop();
  }
}
