import { Component, OnInit } from '@angular/core';
import { AppComponent } from '../../../../app.component'

@Component({
  selector: 'app-artifacts-main',
  templateUrl: './artifacts-main.component.html',
  styleUrls: ['./artifacts-main.component.scss']
})
export class ArtifactsMainComponent implements OnInit {

  constructor(public appComponent: AppComponent) { }

  ngOnInit(): void {
  }

}
