import { Component, Input, OnInit } from '@angular/core';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { TabName } from 'src/app/common/constants';
import { TabService } from 'src/app/core/artifacts/services/tab.service';

@Component({
  selector: 'app-artifact-list',
  templateUrl: './artifact-list.component.html',
  styleUrls: ['./artifact-list.component.scss'],
  providers: [TabService]
})
export class ArtifactListComponent {
  @Input() userRoles?: string[];
  currentTab: string = TabName.ALL_TAB;
  
  constructor() {}

  updateCurrentTab(event: MatTabChangeEvent): void {
    this.currentTab = event.tab.textLabel;
  }
}
