<mat-expansion-panel
  [ngStyle]="{background: getColor(artifact.deleted)}"
  hideToggle
  [class.selected]="artifact === selectedArtifact"
  [class.usertest]="artifact.buildTag.includes('USER_TEST')"
  (click)="onSelect(artifact)"
>
    <mat-expansion-panel-header collapsedHeight="100%" expandedHeight="100%">
      <mat-panel-title>
        <div>
          <img [src]="displayImage(artifact)" width="64" height="64"/>
        </div>
        <div>
          <h3 class="app-name">{{ artifact.appName }}</h3>
          <h5 class="file-name">&nbsp;File name: {{ artifact.fileName }}</h5>
        </div>
      </mat-panel-title>

      <mat-panel-description class="right-aligned">
            <span>
              #{{ artifact.buildNumber }} | {{ artifact.appVersion }} |
              {{ artifact.appPlatform }} | {{ artifact.buildType }} |
              {{ artifact.branch }}
            </span>
      </mat-panel-description>
    </mat-expansion-panel-header>

    <div class="detail">
      <div>
        <app-artifact-detail
          [userRoles]="userRoles"
          [artifact]="selectedArtifact"
          [currentTab]="currentTab"
        ></app-artifact-detail>
      </div>
    </div>
</mat-expansion-panel>

