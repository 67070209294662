<div class="list-container">
  <mat-tab-group
    mat-stretch-tabs
    animationDuration="0ms"
    (selectedTabChange)="updateCurrentTab($event)"
  >
    <mat-tab label="All">
      <ng-template matTabContent>
        <app-artifacts
          [userRoles]="userRoles"
          [currentTab]="currentTab"
        ></app-artifacts>
      </ng-template>
    </mat-tab>
    <mat-tab label="User Test">
      <ng-template matTabContent>
        <app-artifacts
          [userRoles]="userRoles"
          [currentTab]="currentTab"
        ></app-artifacts>
      </ng-template>
    </mat-tab>
    <mat-tab label="Archive">
      <ng-template matTabContent>
        <app-artifacts
          [userRoles]="userRoles"
          [currentTab]="currentTab"
        ></app-artifacts>
      </ng-template>
    </mat-tab>
  </mat-tab-group>
</div>
