import {Component, Input} from '@angular/core';
import {DomSanitizer} from '@angular/platform-browser';
import {BuildTypes, KeycloakRole, Platforms} from 'src/app/common/constants';
import {ArtifactService} from 'src/app/core/artifacts/artifact.service';
import {Artifact} from 'src/app/core/artifacts/classes/artifact';
import {environment} from 'src/environments/environment';
import {DialogData, UploadDialogComponent} from '../upload-dialog/upload-dialog.component';
import {MatDialog} from '@angular/material/dialog';
import {ArchiveDialogComponent, ArchiveDialogData} from '../archive-dialog/archive-dialog.component';
import {ArtifactsComponent} from '../artifacts/artifacts.component';
import {TestFlightGroup} from 'src/app/core/artifacts/classes/test-flight-group';
import {UploadFeedback} from 'src/app/core/artifacts/classes/upload-feedback';
import {RemoveDialogComponent} from '../remove-dialog/remove-dialog.component';
import {RestoreDialogComponent} from '../restore-dialog/restore-dialog.component';
import { QRCode } from 'qrcode-generator-ts/js';

@Component({
  selector: 'app-artifact-detail',
  templateUrl: './artifact-detail.component.html',
  styleUrls: ['./artifact-detail.component.scss'],
})
export class ArtifactDetailComponent {
  @Input() artifact?: Artifact;
  @Input() userRoles?: string[];
  @Input() currentTab?: string;
  @Input() isInProgress = false;
  @Input() isArchived = false;
  @Input() isDeleted = false;
  @Input() isRestored = false;

  feedback: any;
  uploadFeedback: UploadFeedback | undefined;

  constructor(
    public artifactService: ArtifactService,
    private sanitizer: DomSanitizer,
    private dialog: MatDialog,
    private artifactsComponent: ArtifactsComponent
  ) {}

  generatePlistUrl() {
    let baseUrl = 'itms-services://?action=download-manifest&url=';
    return this.sanitizer.bypassSecurityTrustUrl(
      baseUrl +
        environment.api.url +
        '/dashboard/plist/' +
        this.artifact!.id +
        '.plist'
    );
  }

  archive() {
    let stores = this.artifactsComponent.config?.buildStores
      .filter(store => {
        if (this.artifact?.appPlatform == Platforms.IOS_PLATFORM) {
          return store.includes("Appstore")
        } else {
          return !store.includes("Appstore")
        }
      })
      .map(store => {
        return {
          name: store,
          chosen: false
        }
      })

    const dialogRef = this.dialog.open(ArchiveDialogComponent, {
      data: {
        artifact: this.artifact,
        buildStores: stores
      }
    });

    dialogRef.afterClosed().subscribe((result: ArchiveDialogData) => {
      if (result == undefined) {
        this.artifactService.error = `Operation archiveBuild for artifact ${this.artifact?.fileName} has failed - archiving did not start. Reason: Missing parameters. Please enter all parameters before proceeding.`;
        return;
      } else {
        this.artifactService.error = undefined;
      }

      this.isInProgress = true;

      let chosenStores = result.buildStores
        .filter(store => store.chosen)
        .map(store => store.name)

      this.artifactService
        .archiveArtifact(this.artifact!.id, chosenStores)
        .subscribe((response) => {
          if (response != undefined) {
            this.feedback = `Operation archiveBuild for chosen build stores: ${chosenStores.join(", ")} has been successful. Actually were archived builds for the following stores: ${JSON.stringify(response)}`;
            this.isArchived = true;

          }
          this.isInProgress = false;
        });
    });
  }

  checkArchiveUser(): boolean {
    return this.userRoles!.includes(KeycloakRole.ALLOW_ARCHIVING);
  }

  checkQAMacTesterUser(): boolean {
    return this.userRoles!.includes(KeycloakRole.ALLOW_IOS_DOWNLOAD);
  }

  checkReleaseBuildDownload(artifact: Artifact): boolean {
    return artifact.buildType.toLowerCase() === BuildTypes.RELEASE.toLowerCase() && artifact.appPlatform.toLowerCase() === Platforms.ANDROID_PLATFORM.toLowerCase() && this.checkArchiveUser()
  }

   /**
   * Creates a QR code to be scanned with the AppHub onegaj
   * @param artifact Firestore document
   * @returns Image of firestore document (data url)
   */
  getQrCodeDeeplinkDataURL(artifact: Artifact) {
      let deeplink = "o7apphub://install?appClassId=" + artifact.appClassId + "&buildType=" + artifact.buildType + "&buildNumber=" + artifact.buildNumber
      var qr = new QRCode();
      qr.setTypeNumber(5);
      qr.addData(deeplink); // Alphabet and Number
      qr.make();
      return qr.toDataURL();
  }

  downloadArtifactWithId(buildType?: string): void {
    this.isInProgress = true;
    this.artifactService
      .downloadArtifact(this.artifact?.id, buildType)
      .subscribe((data) => {
        const element = document.createElement('a')
        element.href = data as string
        element.click();
        this.isInProgress = false;
      });
  }

  uploadBuild() {

    let testFlightGroups: TestFlightGroup[] = []
    this.artifactService.getTestFlightGroups(this.artifact?.appClassId!!)
      .subscribe(
        groups => {
          testFlightGroups = groups.map(group => {
            return {
              name: group,
              chosen: false
            }
          });

          const dialogRef = this.dialog.open(UploadDialogComponent, {
            data: {
              artifact: this.artifact,
              testFlightGroups: testFlightGroups,
              publish: undefined,
              overwriteTexts: false,
              track: ''
            }
          });

        dialogRef.afterClosed().subscribe(result => {

          let returnedData = result as DialogData;

          if(result == undefined) {
            this.artifactService.error = "Operation uploadToTheStore has failed - upload did not start. Reason: Missing parameters. Please enter all parameters before proceeding.";
            this.feedback = undefined;
            this.uploadFeedback = undefined;
            return;
          } else {
            this.artifactService.error = undefined;
            this.feedback = undefined;
            this.uploadFeedback = undefined;
          }

          let testGroups = '';
          if (returnedData.testFlightGroups.length != 0 && returnedData.testFlightGroups.filter(group => group.chosen).length != 0) {
            testGroups = returnedData.testFlightGroups
              .filter(group => group.chosen)
              .map(group => group.name)
              .reduce((acc, currValue) => acc + ',' + currValue);
          }

          let track = returnedData.track;
          let publish = returnedData.publish == 'true';
          let overwriteTexts = returnedData.overwriteTexts;
          let flavour = this.artifact?.buildStore!!;

          this.artifactService.uploadToCircleCIPipeline(
            this.artifact?.appPlatform!!,
            this.artifact?.appClassId!!,
            Number(this.artifact?.buildNumber),
            this.artifact?.buildType!!,
            publish,
            track,
            flavour,
            testGroups,
            overwriteTexts
          ).subscribe(response => {
            if (response != undefined) {
              this.uploadFeedback = response;
            }
          });
        });
      });

  }
  removeBuild() {
    const dialogRef = this.dialog.open(RemoveDialogComponent, {
      data: {
        artifact: this.artifact,
        deleted: true
      }
    });

    dialogRef.afterClosed().subscribe((result: ArchiveDialogData) => {
      if (result == undefined) {
        this.artifactService.error = `Operation deleteArtifact for artifact ${this.artifact?.fileName} has failed - remove did not start. Reason: Missing parameters. Please enter all parameters before proceeding.`;
        return;
      }
      this.artifactService.error = undefined;

      this.isInProgress = true;
      this.artifactService
        .deleteArtifact(this.artifact!.id)
        .subscribe((response) => {
          if (response != undefined) {
            this.feedback = `Operation deleteArtifact for artifact ${this.artifact?.fileName} has been successful, build has been removed and will be invisible for other users.`;
            this.isDeleted = true;
          }
          this.isInProgress = false;
        });
    });

  }

  restoreBuild() {
    const dialogRef = this.dialog.open(RestoreDialogComponent, {
      data: {
        artifact: this.artifact,
        deleted: false
      }
    });

    dialogRef.afterClosed().subscribe((result: ArchiveDialogData) => {
      if (result == undefined) {
        this.artifactService.error = `Operation restoreArtifact for artifact ${this.artifact?.fileName} has failed - restore did not start. Reason: Missing parameters. Please enter all parameters before proceeding.`;
        return;
      }
      this.artifactService.error = undefined;

      this.isInProgress = true;
      this.artifactService
        .restoreArtifact(this.artifact!.id)
        .subscribe((response) => {
          if (response != undefined) {
            this.feedback = `Operation restoreArtifact for artifact ${this.artifact?.fileName} has been successful, build has been restored and will be visible again for other users.`;
            this.isRestored = true;
          }
          this.isInProgress = false;
        });
    });

  }

}
