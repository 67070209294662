<div class="alert alert-danger alert-server" role="alert" style="margin-bottom: 0; border-radius: 0; text-align: center;">
    <strong>End-of-life Announcement</strong>
    <br>
    Dist 2.0 Web is approaching end-of-life and will be shut down soon (end of September). We recommend that you switch to <a href="https://app-rocket.visual7.app/builds" style="color: #721c24; font-weight: bold;">AppRocket</a>.
    <br>
    In case of any missing features, please, contact #platform-engineering-support.
</div>
<div class="container-fluid">
  <mat-toolbar>
    <a routerLink="/">
      <span>Dist 2.0</span>
    </a>
    <span class="space"></span>
    <button mat-button>
      <a routerLink="/apphub">Apphub</a>
      <mat-icon aria-hidden="false">pets</mat-icon>
    </button>
    <button mat-icon-button (click)="logout()">
      <mat-icon>logout</mat-icon>
    </button>
  </mat-toolbar>

  <router-outlet></router-outlet>
</div>
